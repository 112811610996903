import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { useCallback, useEffect, useRef } from "react";
import { ColDef, ValueFormatterFunc } from "ag-grid-community";
import { NumericCellEditor } from "./grid/NumericCellEditor";
import { fetchDelete, fetchPut } from "../utils/fetchHelper";
import { trackingUrl } from "../services/ricapi";
import { BtnCellRenderer } from "./grid/ButtonCellRender";

const frameworkComponents = {
  numericCellEditor: NumericCellEditor,
  btnCellRenderer: BtnCellRenderer,
};
const formatNumber = (number: number) => {
  // this puts commas into the number eg 1000 goes to 1,000,
  // i pulled this from stack overflow, i have no idea how it works
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

const currencyFormatter: ValueFormatterFunc = (params) => {
  return formatNumber(params.value) + ".-";
};
const defaultColDef = {
  sortable: true,
  width: 80,
  resizable: true,
};
const columnDefs: ColDef[] = [
  {
    width: 170,
    headerName: "Recherche",
    field: "searchQuery",
    editable: true,
  },
  {
    headerName: "Prix Min.",
    field: "priceMin",
    editable: true,
    cellEditor: "numericCellEditor",
    valueFormatter: currencyFormatter,
  },
  {
    headerName: "Prix Max.",
    field: "priceMax",
    editable: true,
    cellEditor: "numericCellEditor",
    valueFormatter: currencyFormatter,
  },
  {
    headerName: "Exclure",
    field: "excludedKeywords",
    editable: true,
  },
  {
    headerName: "Inclure",
    field: "includedKeywords",
    editable: true,
  },
  {
    headerName: "Nb. de Match",
    width: 110,
    field: "matchCount",
  },
  {
    headerName: "ID catégorie",
    field: "categoryId",
  },
  {
    headerName: "ID",
    field: "id",
    width: 60,
  },
];
export const TrackingGrid = ({ data, onUpdate }: any) => {
  const gridRef = useRef<any>();

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.refreshCells();
      gridRef.current.columnApi.applyColumnState({
        state: [{ colId: "id", sort: "desc", sortIndex: 1 }],
        defaultState: { sort: null },
      });
    }
  }, [data]);

  const onGridReady = useCallback((params: any) => {
    params.api.refreshCells();
  }, []);

  const onDelete = useCallback(
    async (event: any) => {
      if (window.confirm("Es-tu sûr de vouloir supprimer ce tracking ?")) {
        const id = event.node.data.id;
        await fetchDelete(trackingUrl, { ids: `${id}` });
        onUpdate();
      }
    },
    [onUpdate]
  );

  const onCellEditRequest = useCallback(
    async (event: any) => {
      const payload = {
        id: event.data.id,
        [event.colDef.field]: event.value,
      };
      await fetchPut(trackingUrl, payload);
      onUpdate();
    },
    [onUpdate]
  );

  return (
    <div className="ag-theme-balham w-full h-full">
      <AgGridReact
        ref={gridRef}
        defaultColDef={defaultColDef}
        columnDefs={[
          ...columnDefs,
          {
            field: "actions",
            cellRenderer: "btnCellRenderer",
            cellRendererParams: {
              clicked: onDelete,
            },
          },
        ]}
        rowHeight={30}
        rowSelection="single"
        rowData={data}
        enableCellChangeFlash={true}
        onGridReady={onGridReady}
        readOnlyEdit={true}
        onCellEditRequest={onCellEditRequest}
        frameworkComponents={frameworkComponents}
      />
    </div>
  );
};
