import { Component } from "react";
import { TrashIcon } from "@heroicons/react/outline";
import { IconButton } from "../../design-system/IconButton";

export class BtnCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }
  btnClickedHandler() {
    this.props.clicked(this.props);
  }
  render() {
    return (
      <IconButton
        size="small"
        onClick={this.btnClickedHandler}
        icon={
          <TrashIcon
            style={{ padding: "1px 0px 0px 2px" }}
            height="100%"
            preserveAspectRatio="xMinYMin slice"
            width="100%"
            viewBox="0 0 30 30"
          />
        }
      />
    );
  }
}
