import { Button } from "antd";
import { cloneElement } from "react";

export const IconButton = ({ icon, ...rest }: any) => {
  return (
    <Button className="!px-0" type="text" {...rest}>
      {cloneElement(icon, { className: "h-5 w-5 mx-1" })}
    </Button>
  );
};
