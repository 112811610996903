import Calendar from "hexadeciman-react-github-contribution-calendar";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { getMatchesUrl } from "../services/ricapi";
import { fetchGet } from "../utils/fetchHelper";
const weekNames = ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"];
const monthNames = [
  "Jan.",
  "Fev.",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juil.",
  "Aout",
  "Sept.",
  "Oct.",
  "Nov.",
  "Dec.",
];
const panelColors = ["#EEEEEE", "#D6E685", "#8CC665", "#44A340", "#1E6823"];
export const CalendarWidget = () => {
  const [values, setValues] = useState({});

  const fetchCalendar = useCallback(async () => {
    const data = await fetchGet(getMatchesUrl);
    const values = data.reduce((acc: any, { matchDay, matchCount }: any) => {
      acc[DateTime.fromISO(matchDay).toISODate()] = matchCount;
      return acc;
    }, {});
    setValues(values);
  }, []);

  // on Mount
  useEffect(() => {
    fetchCalendar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Calendar
      values={values}
      until={DateTime.fromJSDate(new Date()).toISODate()}
      weekNames={weekNames}
      monthNames={monthNames}
      panelColors={panelColors}
      dateFormat="YYYY-MM-DD"
      weekLabelAttributes={undefined}
      monthLabelAttributes={undefined}
      panelAttributes={undefined}
    />
  );
};
