import "./App.css";
import { Button } from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";
import { ProductCard } from "./ProductCard";
import { SearchForm } from "./SearchForm";
import { TrackingGrid } from "./TrackingGrid";
import { H1, SPAN } from "../design-system/Typography";
import { Ping } from "./Ping";
import {
  CalendarIcon,
  PlusCircleIcon,
  RefreshIcon,
  XIcon,
} from "@heroicons/react/outline";

import { IconButton } from "../design-system/IconButton";
import { useViewManager } from "../hooks/useViewManager";
import { CalendarWidget } from "./CalendarWidget";
import { fetchGet } from "../utils/fetchHelper";
import { trackingUrl } from "../services/ricapi";

export const App = () => {
  const [results, setResults] = useState<any[]>([]);
  const [trackingData, setTrackingData] = useState([]);
  const {
    columnState,
    gridTemplateColumns,
    toggleCalendarCB,
    toggleSearchCB,
    showTrackingView,
    isMobile,
  } = useViewManager();

  const onUpdate = useCallback(async () => {
    const fetchData = async () => {
      const data = await fetchGet(trackingUrl);
      setTrackingData(data);
      showTrackingView();
    };
    fetchData().catch((e) => {
      console.error(e);
      showTrackingView();
    });
  }, [showTrackingView]);

  // onMount effect
  useEffect(() => {
    onUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`grid grid-rows-1 overflow-hidden h-full px-2 `}
      style={{
        gridTemplateColumns,
      }}
    >
      <div className="w-full py-2 grid grid-rows-[min-content_min-content_minmax(0,1fr)] gap-2 rounded">
        {columnState[0] && (
          <Fragment>
            <div className="flex justify-between items-center">
              <H1>Nouveau Tracking</H1>
              {isMobile && (
                <IconButton onClick={toggleSearchCB} icon={<XIcon />} />
              )}
            </div>
            <SearchForm onUpdate={onUpdate} setResults={setResults} />
            <div className="overflow-y-auto">
              {results && results.map((r) => <ProductCard key={r.id} {...r} />)}
            </div>
          </Fragment>
        )}
      </div>
      <div className="w-full mp-2 px-2 pt-2 grid grid-rows-[min-content_minmax(0,1fr)] gap-2">
        {columnState[1] && (
          <Fragment>
            <div className="flex justify-between items-center">
              {!isMobile && (
                <IconButton
                  onClick={toggleSearchCB}
                  icon={<PlusCircleIcon />}
                />
              )}

              <div className="flex">
                <Ping />
                <H1 className="ml-6 mr-2">Live Tracking</H1>
                {!isMobile && (
                  <Button className="!px-0" onClick={onUpdate}>
                    <RefreshIcon className="h-5 w-5 mx-1" />
                  </Button>
                )}
              </div>
              <div className="grid grid-flow-col auto-cols-auto gap-2">
                {isMobile && (
                  <Fragment>
                    <Button type="text" className="!px-0" onClick={onUpdate}>
                      <RefreshIcon className="h-5 w-5 mx-1" />
                    </Button>
                    <IconButton
                      onClick={toggleSearchCB}
                      icon={<PlusCircleIcon />}
                    />
                  </Fragment>
                )}
                <IconButton
                  onClick={toggleCalendarCB}
                  icon={<CalendarIcon />}
                />
              </div>
            </div>

            {trackingData.length > 0 && (
              <TrackingGrid onUpdate={onUpdate} data={trackingData} />
            )}
          </Fragment>
        )}
      </div>
      <div className="w-full pt-2 grid grid-rows-[min-content_1fr] gap-3">
        {columnState[2] && (
          <Fragment>
            <div>
              <div className="flex justify-between mb-2 items-center">
                <H1>Calendrier</H1>
                {isMobile && (
                  <IconButton onClick={toggleCalendarCB} icon={<XIcon />} />
                )}
              </div>
              <CalendarWidget />
            </div>
            <div>
              <H1>Derniers Résultats</H1>
              <SPAN>à venir..</SPAN>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};
