import moment from "moment";
export const ProductCard = ({
  image,
  title,
  buyNowPrice,
  creationDate,
  id,
}: any) => {
  var date = moment(creationDate);
  var dateComponent = date.utc().format("DD-MM-YYYY");
  var timeComponent = date.utc().format("HH:mm");
  return (
    <div className="border border-gray-200 shadow-lg bg-white rounded mb-4  flex">
      <div
        style={{
          backgroundImage: `url("${image.replace(
            "{{TRANSFORMATION}}",
            "t_200x150"
          )}")`,
        }}
        className="w-[70px] h-[70px] bg-black shrink-0 bg-cover rounded rounded-r-none"
      ></div>
      <div className="flex-col items-stretch p-2 truncate w-full ...">
        <a
          href={`https://www.ricardo.ch/fr/a/${id}`}
          target="_blank"
          rel="noreferrer"
          className="text-gray-900 font-bold text-xl mb-2 "
        >
          {title}
        </a>
        <div className="w-full flex items-end justify-between">
          <p className="text-xs text-gray-600 mb-0">
            {dateComponent} à {timeComponent}
          </p>
          <span className="px-2 py-1 mr-1 text-xs font-bold text-black bg-gray-200 rounded">
            {buyNowPrice} CHF
          </span>
        </div>
      </div>
    </div>
  );
};
