import React, { Component } from "react";
import { InputNumber } from "antd";

export class NumericCellEditor extends Component {
  currentValue;
  constructor(props) {
    super(props);
    this.currentValue = props.value;
    this.textInputRef = React.createRef();
  }

  onKeyPress(event) {
    if (!isNumeric(event.nativeEvent)) {
      event.preventDefault();
    }

    function isNumeric(event) {
      return /\d/.test(event.key);
    }
  }

  onKeyDown(event) {
    if (event.keyCode === 39 || event.keyCode === 37) {
      event.stopPropagation();
    }
  }

  afterGuiAttached() {
    //  if (this.textInput) this.textInput.current.focus();
  }

  getValue() {
    const val = parseInt(this.textInputRef.current.getAttribute("value"));
    return isNaN(val) ? this.currentValue : val;
  }

  componentDidMount() {
    this.textInputRef.current.focus();
  }

  setRef(ref) {
    this.textInput = ref;
  }

  render() {
    return (
      <InputNumber
        onKeyPress={this.onKeyPress}
        ref={this.textInputRef}
        defaultValue={this.props.value}
      />
    );
  }
}
