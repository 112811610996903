export const H1 = ({ className, children, ...rest }: any) => {
  return (
    <h1
      className={`text-3xl leading-8 font-extrabold tracking-tight text-gray-900 mb-0 ${className}`}
      {...rest}
    >
      {children}
    </h1>
  );
};

export const SPAN = ({ ...rest }: any) => {
  return <span className={`text-m tracking-tight text-gray-900`} {...rest} />;
};
