import { Form, Input, Cascader, InputNumber, Button, Space } from "antd";
import { useCallback, useState } from "react";
import useDebouncedEffect from "../hooks/useDebouncedEffect";
import {
  loadChildrenCategoriesUrl,
  searchUrl,
  trackingUrl,
} from "../services/ricapi";

import { fetchGet, fetchPost } from "../utils/fetchHelper";

const displayRender = (labels: any, selectedOptions: any) =>
  labels.map((label: any, i: number) => {
    const option = selectedOptions[i];
    if (option && label && i === labels.length - 1) {
      return (
        <span key={option.value}>{label.split ? label.split("-")[0] : ""}</span>
      );
    }

    return null;
  });

export const SearchForm = ({ setResults, onUpdate }: any) => {
  const [form] = Form.useForm();
  const nameValue = (Form as any).useWatch("name", form);
  const [isReadyToBeTracked, setIsReadyToBeTracked] = useState(false);
  const [options, setOptions] = useState([]);
  const [trackingParams, setTrackingParams] = useState<any>({});

  const initialCategories = useCallback(async (name?: string) => {
    const children = await fetchGet(
      loadChildrenCategoriesUrl,
      name
        ? {
            name,
          }
        : {}
    );
    setOptions(
      children.map((child: any) => ({
        ...child,
        name: `${child.name} - ${child.count}`,
      }))
    );
  }, []);

  useDebouncedEffect(
    () => {
      initialCategories(nameValue);
    },
    200,
    [nameValue]
  );

  const loadData = useCallback(
    async (selectedOptions: any) => {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      const children = await fetchGet(loadChildrenCategoriesUrl, {
        id: targetOption.id,
        name: nameValue,
      });
      targetOption.loading = false;
      if (children.length > 0) {
        targetOption.children = children.map((child: any) => ({
          ...child,
          name: `${child.name} - ${child.count}`,
        }));
        setOptions([...options]);
      } else {
        targetOption.isLeaf = true;
        setOptions([...options]);
      }
    },
    [nameValue, options]
  );

  const onFinish = async ({ categoryId, ...rest }: any) => {
    const queryVariables = {
      categoryId: categoryId[categoryId.length - 1],
      ...rest,
    };
    setTrackingParams(queryVariables);
    const searchResults = await fetchGet(searchUrl, queryVariables);
    if (searchResults.length >= 0) {
      setIsReadyToBeTracked(true);
    }
    setResults(searchResults);
  };

  const onFormChange = useCallback(() => {
    setIsReadyToBeTracked(false);
  }, []);

  const addTracking = useCallback(async () => {
    if (window.confirm("Es-tu sûr de vouloir ajouter ce tracking ?")) {
      await fetchPost(trackingUrl, {
        ...trackingParams,
        search: trackingParams.name ?? "",
      });
      onUpdate();
      form.resetFields();
      setIsReadyToBeTracked(false);
    }
  }, [form, onUpdate, trackingParams]);

  return (
    <div className="p-3 border border-gray-200 shadow-lg">
      <Form
        form={form}
        labelAlign="left"
        name="basic"
        layout="horizontal"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        colon={false}
        autoComplete="off"
        size="middle"
        labelWrap
        onChange={onFormChange}
        style={{ display: "grid", gridTemplateColumns: "1fr", width: "100%" }}
      >
        <Form.Item rules={[{ required: true }]} label="Mots clés" name="name">
          <Input placeholder="iPhone 13 Pro" />
        </Form.Item>
        <Form.Item
          style={{ width: "100%" }}
          rules={[{ required: true }]}
          name="categoryId"
        >
          <Cascader
            options={options}
            loadData={loadData}
            style={{ width: "100%" }}
            placeholder="Selectionnez la categorie du produit"
            fieldNames={{
              label: "name",
              value: "id",
            }}
            displayRender={displayRender}
            changeOnSelect
          />
        </Form.Item>
        <Space>
          <Form.Item rules={[{ required: true }]} name="priceMin">
            <InputNumber placeholder="Prix Minimal" addonAfter="CHF" />
          </Form.Item>
          <Form.Item rules={[{ required: true }]} name="priceMax">
            <InputNumber placeholder="Prix Maximal" addonAfter="CHF" />
          </Form.Item>
        </Space>
        <div className="flex justify-between">
          <Form.Item>
            <Button htmlType="submit">Rechercher</Button>
          </Form.Item>
          {isReadyToBeTracked && (
            <Button type="primary" onClick={addTracking}>
              Ajouter au Tracking
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};
